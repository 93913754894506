.box{
    background-color: #222934;
    height: 100vh;
}
#root{
    height: 100vh;
}
.login-button{
    color: white !important;
    background-color: #68a7c6 !important;
    border: 1px solid #68a7c6 !important;
}
.login-button:hover{
    background-color: #68a7c6 !important;
}
.error-message{
    color:red !important;
    font-size: 0.8em;
}
